'use strict';
import jQuery from 'jquery'
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/dropdown';
import bootstrapSlider from 'bootstrap-slider';

//import doT from 'dot';

global.$ = global.$3 = global.jQuery = global.jQuery3 = jQuery;
require('owl.carousel/dist/owl.carousel');
require('jquery-autocomplete');
require('selectric')(jQuery);
require('malihu-custom-scrollbar-plugin')(jQuery);
global.bootstrapSlider = bootstrapSlider;
//global.doT = doT;
global.Cookies = require('js-cookie');
